
.matrix-table {
  tbody .flow-node-table-label{
    padding: 3px 12px 3px 20px;
    width: 150px;
    background: #f3faff !important;
    color: #798999;
    position: relative;
    background-clip: padding-box;
    line-height: 1.2;
  }
  .hover-row{
    td{
      &:not(.flow-node-table-label){
        background: #fff !important;
      }
    }
  }
  .headHide{
    .el-table__header-wrapper{
      display:none;
    }
  }
}
